import React from 'react'
import { Link } from 'gatsby'
import styles from './styles.module.css'
import InfoIcon from './icon-info.svg'
import DocSearch from './DocSearch'
import FavoriteContext from '../../favoriteContext'
import BadgeIcon from '../FavoriteButton/icon-star-solid.svg'

const Header = ({ siteTitle, linksSlot }) => (
  <header className={styles.headerContainer}>
    <div className={styles.header}>
        <div className={styles.chartsLinks}>{linksSlot}</div>
        <nav className={styles.logo}>
          <Link to="/" title="Graphopedia home">
            <picture>
              <source srcSet="/logo-small.svg" media="(max-width: 600px)" />
              <source srcSet="/logo.svg" />
              <img src="/logo.svg" alt="Graphopedia" />
            </picture>
          </Link>
        </nav>
        <DocSearch />
        <Link  to="/favorite" title="Favorite Charts">
          <FavoritesBadge />
        </Link>
        <Link  to="/about" className={styles.aboutBtn} title="About Graphopedia">
          <InfoIcon />
        </Link>
    </div>
  </header>
)

const FavoritesBadge = () => {
  const {charts} = React.useContext(FavoriteContext);
  const total = Object.keys(charts).length;
  return (
    <div className={styles.badgeBtn}>
      <span className={styles.badgeIcon}><BadgeIcon /></span>
      <span className={`${styles.badgeStatus} ${total == 0 && styles.badgeHidden}`}>{total > 99 ? '99‥' : total}</span>
    </div>
  )
}

export default Header
